@import "variables";
/**
Css For the main landing page
Sub Section css are in seperate less files
*/
.main-container.main {
    height: $main-container-height--mobile;
    background-size: $mobile-background-width;
    width: 100%;
    @media #{$for-desktop} {
        background: none;
        height: $main-container-height--desktop;
    }
}

.main {
    .logo-section {
        position: relative;
    }
    .logo-anim-container {
        width: 100vw;
        left: 0;
        position: fixed;
        will-change: transform;

        @media #{$for-desktop} {
            margin: 0 auto;
        }

        .logo-anim-positioner {
            // position the logo relative to main content margin
            transform-origin: 0% 0%;
            // transform: translate(-40px,0);
            @media #{$for-desktop} {
                margin: 0 auto;
                width: $desktop-breakpoint;
                max-width: $desktop-breakpoint;
            }
        }

        .lottie_anim-logo {
            height: $mobile-background-width * $mobile-background-scale;
            width: $mobile-background-width * $mobile-background-scale;
            max-width: 100vw;
            max-height: 100vw;
            position: relative;
            left: -20px; // optical center alignment
            top: -30px;
            margin: 0 auto;

            @media #{$for-desktop} {
                margin: 0;
                height: 630px;
                width: 630px;
                // position is relative to the center-column left edge.
                // account for white space in the logo animation
                left: -130px;
                top: -50px;
            }

            svg {
                object-fit: contain;
                object-position: 0 0;
            }
        }
    }

    #components-waypoint {
        @media #{$for-desktop} {
            margin-top: 500px;
        }
    }

    #methods-waypoint {
        @media #{$for-desktop} {
            top: 1000px;
            position: absolute;
        }
    }

    .main-mobile-decor {
        z-index: -99;
        position: absolute;
        background: url("/img/landing/mobile-decor.svg") no-repeat center;
        height: 350px;
        top: 280px;
        width: 100%;

        @media #{$for-desktop} {
            display: none;
        }
    }

    .main-mobile-decor-2 {
        z-index: -99;
        position: relative;
        background: url("/img/landing/mobile-decor-2.svg") no-repeat center;
        background-position-x: 10px;
        padding-left: 50px;
        height: 150px;
        width: 400px;
        margin: 630px auto;

        @media #{$for-desktop} {
            display: none;
        }
    }

    .main-mobile-decor-3 {
        @extend .main-mobile-decor;
        background: url("/img/landing/mobile-decor-3.svg") no-repeat center;
        top: 1280px;
        height: 1100px;
        left: 10px;
    }
}
