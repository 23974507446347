// main page animation container
// prevents the animations from overflowin the page length and causing white space below footer.
.main-animations-container {
    @media #{$for-desktop} {
        height: $main-container-height--desktop;
        width: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
    }
}

.team-animations-container {
    @media #{$for-desktop} {
        height: $team-container-height--desktop;
        width: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
    }
}
.organization-animations-container {
    @media #{$for-desktop} {
        height: $team-container-height--desktop;
        width: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
    }
}

.simple-animations-container {
    @media #{$for-desktop} {
        width: 100%;
        min-height: 100vh;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
    }
}

.joinus-animations-container {
    @media #{$for-desktop} {
        height: $joinus-container-height--desktop;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
    }
}

/********************* animations landing and menu ***********************/
.animations {
    display: none;
    z-index: -999;
    font-size: 62.5%;

    @media #{$for-desktop} {
        display: block;
        overflow: visible;
        position: absolute;
        top: 0;
        left: 0;
        /* Tweek position of animation relative to the text-align center */
        // right: 35px;
        right: 0;
        margin: auto;
        width: 1440px;
        height: $main-container-height--desktop;
        max-height: $main-container-height--desktop;
        max-width: 1440px;
    }
}

.anim {
    width: 100%;
}

.anim-methods {
    display: none;

    @media #{$for-desktop} {
        margin: 20px 0 0;
        display: flex;
        justify-content: center;
        // top: 28em;
        // left: 52%;
        // transform: translateX(-50%);
    }
}

.anim-ico {
    min-width: 30%;
    display: block;
}

// landing page setup
.animations--new {
    width: $desktop-background-width;
    margin: 0 auto;
    height: 100%;
    position: relative;
    overflow: visible;
    text-align: center;
    display: none;

    @media #{$for-desktop} {
        display: block;
    }

    .anim {
        overflow: visible;
        position: absolute;
        top: $desktop-bg-native-y-offset * $desktop-background-scale * -1;
        // center the layer
        left: (
                $desktop-bg-native-width * $desktop-background-scale -
                    $desktop-background-width
            ) * -0.5;
        z-index: -999;
        width: $desktop-bg-native-width * $desktop-background-scale !important;
        height: auto !important;
        svg {
            vertical-align: top !important;
            object-fit: none;
            object-position: center top;
            overflow: visible;
            width: 100%;

            g {
                clip-path: none !important;
            }
        }
    }

    .lottie_plant-1 {
        z-index: -99;
        left: -1110px;
    }

    .lottie_plant-2 {
        z-index: -99;
        left: -1110px;
    }

    .lottie_plant-3a,
    .lottie_plant-3b,
    .lottie_plant-3c {
        z-index: -100;
        left: -1110px;
    }

    .lottie_plant-boat {
        z-index: -99;
        top: 1000px;
    }

    .lottie_blob-menu {
        z-index: -9999;
    }

    .lottie_blob-middle {
        z-index: -9999;
        left: -1100px;
    }

    .lottie_blob-ico {
        z-index: -9999;
        top: -100px;
        left: -1100px;
    }

    .lottie_blob-ico-2 {
        z-index: -9999;
        top: -200px;
        left: -1100px;
    }
}

/*********************************************************/
/*********************** Animations team ******************/
.team-animations {
    display: none;
    z-index: -99;

    @media #{$for-desktop} {
        width: $desktop-background-width;
        height: 100%;
        overflow: visible;
        margin: 0 auto;
        display: block;
        position: relative;
        top: $team-desktop-bg-native-y-offset;
    }

    .anim {
        overflow: visible;
        position: absolute;
        top: $team-desktop-bg-native-y-offset * $team-desktop-background-scale *
            -1;
        // center the layer
        left: (
                $team-desktop-bg-native-width * $team-desktop-background-scale -
                    $desktop-background-width
            ) * -0.5;
        z-index: -999;
        // left: -50%;
        width: $team-desktop-bg-native-width * $team-desktop-background-scale !important;
        // height: $desktop-bg-native-height * $desktop-background-scale !important;
        height: auto !important;
        svg {
            // position: absolute;
            // left: -50%;
            vertical-align: top !important;
            object-fit: none;
            object-position: center top;
            overflow: visible;
            width: 100%;

            g {
                clip-path: none !important;
            }
        }
    }
}

.lottie_team-node {
    position: absolute;
    // top: 400px;
    left: 0;
}

.lottie_team-Aubo {
    z-index: 9999;
}

.lottie_team-node {
    position: absolute;
    top: 0px;
    left: 0;
}

.lottie_team-blob-1 {
    position: absolute;
    top: 0;
    left: 0;
    // width: 160%;
    z-index: -999;
}

.lottie_team-blob-3 {
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -999;

    @media #{$for-desktop} {
        top: 55em;
    }
}

/*********************************************************/
/*********************** Animations organization ******************/

.organization-animations {
    display: none;
    z-index: -99;

    @media #{$for-desktop} {
        width: $desktop-background-width;
        height: 100%;
        overflow: visible;
        margin: 0 auto;
        display: block;
        position: relative;
        top: -1115px;
        .lottie_organization-blob-1 svg > g {
            transform: translate(-80px, 1140px);
        }
    }

    .anim {
        overflow: visible;
        position: absolute;
        top: $team-desktop-bg-native-y-offset * $team-desktop-background-scale *
            -1;
        // center the layer
        left: (
                $team-desktop-bg-native-width * $team-desktop-background-scale -
                    $desktop-background-width
            ) * -0.5;
        z-index: -999;
        // left: -50%;
        width: $team-desktop-bg-native-width * $team-desktop-background-scale !important;
        // height: $desktop-bg-native-height * $desktop-background-scale !important;
        height: auto !important;
        svg {
            // position: absolute;
            // left: -50%;
            vertical-align: top !important;
            object-fit: none;
            object-position: center top;
            overflow: visible;
            width: 100%;

            g {
                clip-path: none !important;
            }
        }
    }
}

.lottie_organization-node {
    position: absolute;
    // top: 400px;
    left: 0;
}

.lottie_organization-node {
    position: absolute;
    top: 0px;
    left: 0;
}

.lottie_organization-blob-1 {
    position: absolute;
    top: 0;
    left: 0;
    // width: 160%;
    z-index: -999;
}

.lottie_organization-blob-3 {
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -999;

    @media #{$for-desktop} {
        top: 55em;
    }
}

/**
* join us animations
*/
.joinus-animations {
    display: none;
    z-index: -99;

    @media #{$for-desktop} {
        width: $desktop-background-width;
        height: 100%;
        overflow: visible;
        margin: 0 auto;
        display: block;
        position: relative;
        top: $joinus-desktop-bg-native-y-offset;
        max-height: $joinus-container-height--desktop;
    }

    .anim {
        overflow: visible;
        position: absolute;
        top: $joinus-desktop-bg-native-y-offset *
            $joinus-desktop-background-scale * -1;
        // center the layer
        left: (
                $joinus-desktop-bg-native-width *
                    $joinus-desktop-background-scale - $desktop-background-width
            ) * -0.5;
        z-index: -999;
        // left: -50%;
        width: $joinus-desktop-bg-native-width *
            $joinus-desktop-background-scale !important;
        // height: $desktop-bg-native-height * $desktop-background-scale !important;
        height: auto !important;
        svg {
            vertical-align: top !important;
            object-fit: none;
            object-position: center top;
            overflow: visible;
            width: 100%;
        }
    }
}
.lottie_joinus-plant-1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -99;
}
.lottie_joinus-plant-2 {
    position: absolute;
    z-index: -99;
    top: 440px;
    left: 0;
}
.lottie_joinus-blob-1 {
    position: absolute;
    z-index: -999;
    top: 0;
    left: 0;
}
.lottie_joinus-blob-2 {
    position: absolute;
    z-index: -999;
    top: 0;
    left: 0;
}
.lottie_joinus-blob-3 {
    position: absolute;
    z-index: -999;
    top: 0;
    left: 0;
}

/**
* Simple Animations
**/

.simple-animations {
    display: none;
    z-index: -99;

    @media #{$for-desktop} {
        width: $desktop-background-width;
        height: 100%;
        overflow: visible;
        margin: 0 auto;
        display: block;
        position: relative;
        top: $joinus-desktop-bg-native-y-offset;
        max-height: $joinus-container-height--desktop;
    }

    .anim {
        overflow: visible;
        position: absolute;
        top: $joinus-desktop-bg-native-y-offset *
            $joinus-desktop-background-scale * -1;
        // center the layer
        left: (
                $joinus-desktop-bg-native-width *
                    $joinus-desktop-background-scale - $desktop-background-width
            ) * (-0.5);
        z-index: -999;
        // left: -50%;
        width: $joinus-desktop-bg-native-width *
            $joinus-desktop-background-scale !important;
        // height: $desktop-bg-native-height * $desktop-background-scale !important;
        height: auto !important;
        svg {
            vertical-align: top !important;
            object-fit: none;
            object-position: center top;
            overflow: visible;
            width: 100%;
        }
    }
}

.lottie_invoice-blob-1 {
    position: absolute;
    z-index: -999;
    top: 0;
    left: 0;
}
