@import "variables";
/**
* Intro Section
*/

section.intro-section {
    z-index: $main-intro-z-index;
    width: 100%;
    top: $main-intro-section-top--mobile;
    position: absolute;
    padding: 0 $text-column-padding--mobile;

    @media #{$for-desktop} {
        top: $main-intro-section-top--desktop;
        padding: 0 !important;
    }

    & article.intro-article {
        @media #{$for-desktop} {
            position: absolute;
            top: 137px;
            width: 100%;
            margin: 0 auto;
        }

        & h2.intro-text {
            font-size: 15px;
            line-height: 18px;
            overflow: hidden;
            text-align: center;
            max-width: 252px;
            margin: 45px auto 0;
            font-family: BebasNeuePro-Bold;

            @media #{$for-desktop} {
                font-size: $intro-font-size--desktop;
                line-height: $intro-font-line-height--desktop;
                font-weight: 700;
                margin-bottom: 0;
                text-align: left;
                position: absolute;
                left: 40%;
                width: 67%;
                display: block;
                max-width: initial;
                margin-top: 0;
            }
        }
    }
}
