@font-face {
  font-family: 'BebasNeuePro-Bold';
  src: url('../fonts/webFonts/BebasNeuePro-Bold/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-Bold/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-Regular';
  src: url('../fonts/webFonts/BebasNeuePro-Regular/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-Regular/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-Light';
  src: url('../fonts/webFonts/BebasNeuePro-Light/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-Light/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeue-Regular';
  src: url('../fonts/webFonts/BebasNeue-Regular/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeue-Regular/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-Book';
  src: url('../fonts/webFonts/BebasNeuePro-Book/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-Book/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-Middle';
  src: url('../fonts/webFonts/BebasNeuePro-Middle/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-Middle/font.woff') format('woff');
}
/*@font-face {
  font-family: 'BebasNeuePro-BookItalic';
  src: url('../fonts/webFonts/BebasNeuePro-BookItalic/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-BookItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'BebasNeuePro-BoldItalic';
  src: url('../fonts/webFonts/BebasNeuePro-BoldItalic/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-BoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ThinItalic';
  src: url('../fonts/webFonts/BebasNeuePro-ThinItalic/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ThinItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmELg';
  src: url('../fonts/webFonts/BebasNeuePro-SmELg/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmELg/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmEMd';
  src: url('../fonts/webFonts/BebasNeuePro-SmEMd/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmEMd/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmEBdIt';
  src: url('../fonts/webFonts/BebasNeuePro-SmEBdIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmEBdIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmELgIt';
  src: url('../fonts/webFonts/BebasNeuePro-SmELgIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmELgIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmERg';
  src: url('../fonts/webFonts/BebasNeuePro-SmERg/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmERg/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmEBd';
  src: url('../fonts/webFonts/BebasNeuePro-SmEBd/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmEBd/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpRg';
  src: url('../fonts/webFonts/BebasNeuePro-ExpRg/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpRg/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmEBk';
  src: url('../fonts/webFonts/BebasNeuePro-SmEBk/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmEBk/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpMdIt';
  src: url('../fonts/webFonts/BebasNeuePro-ExpMdIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpMdIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmETh';
  src: url('../fonts/webFonts/BebasNeuePro-SmETh/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmETh/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmEEbIt';
  src: url('../fonts/webFonts/BebasNeuePro-SmEEbIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmEEbIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpThIt';
  src: url('../fonts/webFonts/BebasNeuePro-ExpThIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpThIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpLtIt';
  src: url('../fonts/webFonts/BebasNeuePro-ExpLtIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpLtIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpLt';
  src: url('../fonts/webFonts/BebasNeuePro-ExpLt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpLt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmEIt';
  src: url('../fonts/webFonts/BebasNeuePro-SmEIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmEIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpMd';
  src: url('../fonts/webFonts/BebasNeuePro-ExpMd/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpMd/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpEbIt';
  src: url('../fonts/webFonts/BebasNeuePro-ExpEbIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpEbIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpBkIt';
  src: url('../fonts/webFonts/BebasNeuePro-ExpBkIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpBkIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmEMdIt';
  src: url('../fonts/webFonts/BebasNeuePro-SmEMdIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmEMdIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmEEb';
  src: url('../fonts/webFonts/BebasNeuePro-SmEEb/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmEEb/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpTh';
  src: url('../fonts/webFonts/BebasNeuePro-ExpTh/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpTh/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpEb';
  src: url('../fonts/webFonts/BebasNeuePro-ExpEb/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpEb/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpBd';
  src: url('../fonts/webFonts/BebasNeuePro-ExpBd/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpBd/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmEBkIt';
  src: url('../fonts/webFonts/BebasNeuePro-SmEBkIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmEBkIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpIt';
  src: url('../fonts/webFonts/BebasNeuePro-ExpIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpIt/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-ExpBk';
  src: url('../fonts/webFonts/BebasNeuePro-ExpBk/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpBk/font.woff') format('woff');
}

@font-face {
  font-family: 'BebasNeuePro-ExpBdIt';
  src: url('../fonts/webFonts/BebasNeuePro-ExpBdIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-ExpBdIt/font.woff') format('woff');
}

@font-face {
  font-family: 'BebasNeuePro-LightItalic';
  src: url('../fonts/webFonts/BebasNeuePro-LightItalic/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-LightItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-Thin';
  src: url('../fonts/webFonts/BebasNeuePro-Thin/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-Thin/font.woff') format('woff');
}

@font-face {
  font-family: 'BebasNeuePro-MiddleItalic';
  src: url('../fonts/webFonts/BebasNeuePro-MiddleItalic/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-MiddleItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-Italic';
  src: url('../fonts/webFonts/BebasNeuePro-Italic/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-Italic/font.woff') format('woff');
}
@font-face {
  font-family: 'BebasNeuePro-SmEThIt';
  src: url('../fonts/webFonts/BebasNeuePro-SmEThIt/font.woff2') format('woff2'), url('../fonts/webFonts/BebasNeuePro-SmEThIt/font.woff') format('woff');
}*/