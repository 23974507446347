.main-container.single {
  height: $single-container-height--mobile;
  width: 100vw;
  padding: 100px 0 0 0;
  @media #{$for-desktop} {
    padding-top: 100px;
  }

  main {
    // min-height: 100vh;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    min-height: calc(100vh - 450px);
    font-size: $body-2-font-size--mobile;
    line-height: $body-2-font-line-height--mobile;
    padding: 0 $text-column-padding--mobile;

    @media #{$for-desktop} {
      width: $desktop-breakpoint;
      font-size: $body-1-font-size--desktop;
      line-height: $body-1-font-line-height--desktop;
      padding: 0 $text-column-padding--desktop;
    }

    ul {
        padding-left: 3ch;
        li {
            margin: .75em 0;
        }
    }
  }

  h1 {
    font-family: "BebasNeue-Regular";
    font-size: $header-3-font-size--mobile;
    margin: 0;
    margin-bottom: 1em;
    text-align: center;
    @media #{$for-desktop} {
      font-size: $header-2-font-size--desktop;
    }
  }

  a {
    transition: all .2s;
    color: #000;
    text-decoration: none;
    border-bottom-color: $color-green;
    border-bottom-style: solid;
    border-bottom-width: 2px;

    &:hover {
      text-decoration: none;
      color: $color-green;
    }
  }
}
