@import "variables";
@import "breakpoints";
@import "utility";
@import "animations";
@import "mixins";
@import "fonts";

.invisible {
    visibility: hidden;
}

body {
    font-family: "BebasNeuePro-Middle";
    font-size: $body-1-font-size--mobile;
    line-height: $body-1-font-line-height--mobile;
    overflow-x: hidden;
    margin: 0;
    top: 0;
    left: 0;

    @media #{$for-desktop} {
        font-size: $body-1-font-size--desktop;
        line-height: $body-1-font-line-height--desktop;
    }
}

main {
    position: relative;
}

section {
    position: relative;
}

h1,
h2,
h3,
h4,
h5 {
    a {
        color: inherit;
        border-bottom: none !important;
        &:visited {
            color: inherit;
        }
        position: relative;
    }

    // uncomment to add link icons in front of header anchor links on hover:
    // a[href^='#'] {
    //   &:before {
    //     content: '🔗';
    //     color: transparent;
    //     text-shadow: 0 0 #000;
    //     position: absolute;
    //     left: -1.1em;
    //     top: .35em;
    //     font-size: .4em;
    //     // color: black;
    //     opacity: 0;
    //     transition: opacity .5s;
    //   }

    //   &:hover {
    //     &:before {
    //       opacity: .35;
    //     }
    //   }
    // }
}

strong,
b {
    font-family: "BebasNeuePro-Bold";
    font-weight: normal;
}

.f-light {
    font-family: "BebasNeuePro-Book";
}

.f12 {
    font-size: $body-1-font-size--mobile;
    line-height: $body-1-font-line-height--mobile;
    @media #{$for-desktop} {
        font-size: $body-1-font-size--desktop;
    }
}

p.f12 {
    margin-bottom: 0.8em;
}

.ps-mb-2 {
    margin-bottom: 8px;
}

.ps-m-0 {
    margin: 0;
}

.ps-pt-2 {
    padding-top: 8px;
}

p a {
    transition: all 0.2s;
    color: #000;
    text-decoration: none;
    border-bottom-color: $color-green;
    border-bottom-style: solid;
    border-bottom-width: 2px;

    &:hover {
        text-decoration: none;
        color: $color-green;
    }
}

.main-container {
    position: relative;
    z-index: 1;
    background-size: 100%;
    width: $desktop-breakpoint;
    margin: 0 auto;
    min-height: 100vh;

    @media #{$for-desktop} {
        // this is the main page height. the other pages override the heights
        height: $main-container-height--desktop;
        background: none;
        overflow: hidden;
        z-index: $main-container-z-index;
    }
}

h3.intro-head {
    font-size: $header-3-font-size--mobile;
    line-height: 1.25em;
    margin: 0 0 0.6em 0;
    font-family: "BebasNeue-Regular";
    font-weight: 100;

    @media #{$for-desktop} {
        margin-bottom: 1em;
        font-size: $header-2-font-size--desktop;
    }

    &.intro-head2 {
        z-index: inherit;
    }

    &.intro-head3 {
        max-width: 210px;
        margin-left: auto !important;
        margin-right: auto !important;

        @media #{$for-desktop} {
            padding-top: 40px;
            max-width: 620px * $desktop-background-scale;
            margin-bottom: 20px;
            line-height: 1.25em;
        }
    }
}

.ico {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: -4px;

    @media #{$for-desktop} {
        width: 36px;
        height: 36px;
    }
}

.plus-ico {
    background: url(../img/plus.svg) no-repeat top center;
}

.minus-ico {
    background: url(../img/minus.svg) no-repeat top center;
}

.form-control {
    border-radius: 0;
    border: 1px solid $color-green;
    font-size: 12px;
    line-height: 14.4px;
    color: #000;
    font-family: "BebasNeuePro-Book";
    padding: 4px 9px 6px 9px;
    height: 21px;

    &:focus {
        border-color: $color-green;
        outline: 0;
        box-shadow: 0 0 0 0.2rem "rgb(11,175,104,0.25)";
    }
}

.btn-primary {
    background-color: $color-green;
    border-color: $color-green;
    font-size: $button-font-size--mobile;
    line-height: $button-font-line-height--mobile;
    color: #fff;
    font-family: "BebasNeuePro-Bold";
    border-radius: 0;
    letter-spacing: 0.02em;
    padding: 6px 9px;

    @media #{$for-desktop} {
        padding: 12px 18px;
        font-size: $button-font-size--desktop;
        line-height: $button-font-line-height--desktop;
    }

    &:hover {
        background-color: $color-dark-green !important;
        border-color: $color-dark-green !important;
    }

    &:active {
        background-color: $color-dark-green !important;
        border-color: $color-dark-green !important;
        box-shadow: 0 0 0 0.2rem "rgb(11,175,104,0.25)" !important;
    }

    &:focus {
        border-color: $color-green;
        outline: 0;
        box-shadow: 0 0 0 0.2rem "rgb(11,175,104,0.25)" !important;
    }
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #000;
}

.subtitle {
    font-family: "BebasNeue-Regular";
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    @media #{$for-desktop} {
        margin-bottom: 30px;
    }

    .subtitle-ico {
        background: url(../img/sub-ico.svg) no-repeat top center;
        float: left;
        width: 26px;
        height: 32px;

        @media #{$for-desktop} {
            width: 52px;
            height: 64px;
        }
    }

    .subtitle-text {
        font-size: $header-5-font-size--mobile;
        line-height: $header-5-font-line-height--mobile;
        float: left;
        margin-left: 10px;

        @media #{$for-desktop} {
            font-size: $header-3-font-size--desktop;
            line-height: $header-3-font-line-height--desktop;
            margin-left: 20px;
        }
    }
}

.copy {
    color: #fff;
    font-size: $body-2-font-size--mobile;
    line-height: $body-2-font-line-height--mobile;
    text-align: center;
    padding: 10px;

    a {
        color: white;
        border-bottom: 2px solid white;

        &:hover {
            text-decoration: none;
            color: white;
        }
    }
}

article {
    .team {
        position: relative;
        height: 620px;
        @media #{$for-desktop} {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 470px;
        }
    }
    .article-block {
        position: relative;
        max-width: $vw2 * 1px;
        margin: 0 auto;
    }
}

.space {
    padding-bottom: 50px;
}

.logo-big {
    /* Note: The scroll-based scaling is handled in general.js.
    The size of the logo has side-effects in the js.
  */
    position: fixed;
    // margin-left: 50%;
    // margin-right: 50%;
    // left: -350px;
    transform: translateX(-50%);
    width: 100%;
    z-index: $logo-big-z-index;
    display: block;
    left: 0;
    top: 100px;
    a {
        max-width: 800px;
        margin: 0 auto;
        // max-height: 500px;
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    @media #{$for-desktop} {
        max-width: 300px;
        position: absolute;
        margin: 0;
        top: 35px;
        left: 0;
    }
}

.joinus,
.team {
    .logo {
        visibility: hidden;
    }
}

.button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px auto 0;

    @media #{$for-desktop} {
        margin: 40px auto 0;
    }

    .cta {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 40px;
        font-size: 18px;
        background: transparent linear-gradient(180deg, #0BAF68 10%, #15B26E 15%, #78D2AA 95%) 0% 0% no-repeat padding-box;
        border-radius: 11px;
        border: none;
        color: white;
        outline: none;

        @media #{$for-desktop} {
            width: 150px;
            height: 50px;
            font-size: 19px;
        }
    }
}

@import "header.scss";
@import "examples.scss";
@import "main-container.scss";
@import "main-intro-section.scss";
@import "main-works-on-section.scss";
@import "main-approach-section.scss";
@import "main-seed-section.scss";
@import "main-mailing-list-section.scss";
@import "main-news-section.scss";
@import "team.scss";
@import "organization.scss";
@import "joinus.scss";
@import "simple.scss";
@import "single.scss";
@import "sponsor.scss";
@import "footer.scss";
@import "modification-classes.scss";
@import "terms";
@import "interviewing";
