.main-container.joinus,
.main-container.interviewing,
.main-container.team,
.main-container.single,
.main-container.simple,
.main-container.examples,
.main-container.sponsor,
.main-container.organization {
    .header-logo {
        // prevent lax from making header logo transparent on sub-pages
        opacity: 1 !important;
    }
}
.main-container.main {
    .header-bg,
    .header-logo {
        opacity: 0;
    }
}
.header-container {
    transition: all 0.3s ease-in-out;
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: $header-z-index;
    line-height: 0;

    @media #{$for-desktop} {
        background: transparent;
        height: $header-height--desktop;
    }

    .header-positioner {
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        line-height: 0;

        @media #{$for-desktop} {
            max-width: $desktop-breakpoint;
        }
    }

    .header-bg {
        width: 100%;
        height: 100%;
        opacity: 1; // opacity is changed dynamically in JS - see general.js lax functions
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        z-index: -1;
    }

    .header-logo {
        width: 160px;
        height: 60px;
        overflow: visible;
        padding: 10px 0;
        margin: 0 10px;

        @media #{$for-tablet} {
            margin-left: 20px;
        }
        @media #{$for-desktop} {
            width: 200px;
            height: auto;
            margin-left: 80px;
            box-sizing: border-box;
        }

        a {
            display: contents;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center left;
        }
    }

    .menu {
        padding: 20px 10px 10px 10px;
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        overflow: hidden;

        @media #{$for-desktop} {
            padding: 32px 0 10px;
        }

        ul.list {
            display: flex;
            align-items: center;
            align-content: center;
            list-style: none;
            margin: 0;
            margin-right: -350px;
            padding: 0;
            transition: all 0.3s linear;

            @media #{$for-desktop} {
                margin-right: 0;
            }
            li {
                height: 100%;
                border-right: 1px solid $color-green;
                vertical-align: middle;
                line-height: 1em;

                @media #{$for-desktop} {
                    &:last-child {
                        border-right: none;
                    }
                }
                a {
                    display: block;

                    padding: 0 10px;
                    font-size: $menu-font-size--mobile;
                    line-height: 24px;
                    border-bottom: none;
                    color: $color-green;
                    text-decoration: none;

                    @media #{$for-desktop} {
                        padding: 10px 24px;
                        font-size: $menu-font-size--desktop;
                    }
                }
            }
        }

        .menu-button {
            display: block;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            padding: 0 10px 0 10px;
            position: relative;
            box-sizing: border-box;
            height: 38px;
            width: 50px;
            background: white;
            margin-right: 20px;

            @media #{$for-desktop} {
                display: none;
            }

            span {
                transition: all 0.3s ease-in-out;
                display: block;
                float: left;
                background: $color-green;
                width: 1px;
                height: 32px;
                margin-right: 4px;
                transform: rotate(39.5deg);
            }

            span:nth-child(5) {
                padding-right: 0;
            }

            //After element visually hides the menu content to the right of the menu button
            &::after {
                content: "";
                width: 50px;
                height: 50px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(100%, -50%);
                background: #fff;
            }
        }

        &.collapsed {
            // Menu button is in X form
            background: #fff;
            padding-bottom: 0;

            @media #{$for-desktop} {
                background: none;
            }

            .menu-button {
                top: -6px;
                right: 12px;

                span {
                    margin: 0;
                    float: none;
                }

                span:nth-child(1),
                span:nth-child(5) {
                    height: 22px;
                    position: absolute;
                    top: 8px;
                    left: 21px;
                }

                span:nth-child(1) {
                    transform: rotate(45deg);
                }

                span:nth-child(5) {
                    transform: rotate(-45deg);
                }

                span {
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        background: #fff;
                        position: absolute;
                        left: 1000px;
                    }
                }
            }

            ul.list {
                margin-right: 20px;
                .clearfix {
                    clear: both;
                }

                @media #{$for-desktop} {
                    margin-right: 0;
                }
            }

            @media (max-width: 450px) {
                padding-right: 10px;
                ul.list {
                    margin-right: 0;
                }
                .menu-button {
                    margin-right: -15px;
                }
            }
        }
    }
}

@keyframes showMenu {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
