.main-container.organization {
    background: url(../img/team-mobile-bg.svg) no-repeat;
    background-size: 375px;
    background-position-x: center;
    background-position-y: 92px;
    width: 100vw;
    height: 2500px;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    font-size: $body-1-font-size--mobile;
    line-height: $body-1-font-line-height--mobile;
    z-index: 10;

    @media #{$for-desktop} {
        height: 2900px;
        background: none;
        font-size: $body-1-font-size--desktop;
        line-height: $body-1-font-line-height--desktop;
    }

    .organization-header-section {
        margin: 0 auto;
        position: relative;
        // top: $team-mobile-background-scale * 220px;
        left: 0;
        padding-top: $team-mobile-background-scale * 60px;

        @media #{$for-desktop} {
            width: $desktop-background-width;
            top: $team-desktop-background-scale * 340px;
            padding-top: 0;
        }

        h1.organization-header {
            font-size: $header-3-font-size--mobile;
            line-height: $header-3-font-line-height--mobile;
            font-family: "BebasNeue-Regular";
            margin: 0;

            @media #{$for-desktop} {
                font-size: $header-2-font-size--desktop;
            }
        }
    }

    .organization-intro-section {
        position: relative;
        margin-top: 90px;
        width: 100%;
        left: 0;
        padding: 15px ($team-mobile-background-scale * 35px) 0;
        top: 40px;
        @media #{$for-desktop} {
            margin-top: 0;
            position: absolute;
            padding: 0 $text-column-padding--desktop;
            top: 285px;
        }
    }
    .organization-main-element {
        z-index: 999;
        max-width: 375px;
        min-width: 320px;
        width: 100%;
        margin: 210px auto 0 auto;
        position: static;
        min-height: 100%;
        @media #{$for-desktop} {
            margin-top: 0;
            width: auto;
            max-width: none;
        }

        .ways-of-work-section {
            position: relative;
            margin-top: 45px;
            top: 70px;
            padding: 0 ($team-mobile-background-scale * 30px) 0
                $team-mobile-background-scale * 30px;
            overflow-y: visible;
            // max-height: $team-mobile-background-scale * 280px;
            width: 100%;

            @media #{$for-desktop} {
                position: absolute;
                max-height: none;
                margin-top: 0;
                top: 630px;
            }

            p {
                margin-bottom: 0.6em;
            }
            p:first-of-type {
                padding-right: $team-mobile-background-scale * 10px;
            }

            article {
                @media #{$for-desktop} {
                    width: $team-desktop-background-scale * 1100px;
                    margin: 0 auto;
                }
            }
            h2 {
                font-family: "BebasNeue-Regular";
                text-align: center;
                font-size: $header-3-font-size--mobile;
                line-height: $header-3-font-line-height--mobile;
                @media #{$for-desktop} {
                    font-size: $header-2-font-size--desktop;
                    line-height: $header-2-font-line-height--desktop;
                    margin-bottom: 0.5em;
                }
            }
        }

        .team-and-approach-section {
            position: relative;
            margin-top: 102px;
            padding-top: 1em;
            top: 80px;
            padding: 0 ($team-mobile-background-scale * 30px) 0
                ($team-mobile-background-scale * 30px);

            @media #{$for-desktop} {
                margin-top: 0;
                top: 1270px;
            }
            .article-values {
                @media #{$for-desktop} {
                    width: 550px;
                    margin: 0 auto;
                }
            }

            h2.values {
                font-family: "BebasNeue-Regular";
                text-align: center;
                font-size: $header-3-font-size--mobile;
                line-height: $header-3-font-line-height--mobile;
                @media #{$for-desktop} {
                    font-size: $header-2-font-size--desktop;
                    line-height: $header-2-font-line-height--desktop;
                    margin-bottom: 0.5em;
                }
            }

            h2 {
                margin-top: 40px;
                font-family: "BebasNeue-Regular";
                text-align: center;
                font-size: $header-3-font-size--mobile;
                line-height: $header-3-font-line-height--mobile;
                @media #{$for-desktop} {
                    font-size: $header-2-font-size--desktop;
                    line-height: $header-2-font-line-height--desktop;
                    margin-bottom: 0.5em;
                }
            }

            .value-text {
                font-family: "BebasNeuePro-Book";
                //padding: 0px 15px 0 ($team-mobile-background-scale * 30px);

                @media #{$for-desktop} {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }

    .organization-intro {
        font-family: "BebasNeuePro-Book";

        @media #{$for-desktop} {
            width: 555px;
            margin: 0 auto;
        }

        p {
            margin: 0;
            margin-bottom: 0.5em;
        }
    }
}
