@import "variables";
@import "breakpoints";
@import "mixins";
@import "fonts";

.main-container.interviewing {
    background: url(../img/leaf.svg) no-repeat;
    background-size: 200px;
    background-position: left 50px;
    width: 375px;
    height: auto;
    overflow: hidden;
    position: relative;
    font-family: "BebasNeuePro-Book";
    font-size: $body-1-font-size--mobile;
    line-height: $body-1-font-line-height--mobile;
    z-index: 10;

    @media #{$for-desktop} {
        background: none;
        width: 100vw;
        font-size: $body-1-font-size--desktop;
        line-height: $body-1-font-line-height--desktop;
    }

    .art {
        display: none;

        @media #{$for-desktop} {
            display: block;
        }

        .art-left {
            position: absolute;
            top: 220px;
            left: 50%;
            margin-left: -1050px;
            z-index: -1;
            background-image: url("../img/examples/art_left.svg");
            background-size: auto;
            background-repeat: no-repeat;
            width: 700px;
            height: 2000px;
        }
    }

    main {
        width: 700px;
        max-width: 100%;
        margin: 200px auto;

        .interviewing-header {
            font-size: $header-2-font-size--mobile;
            line-height: $header-3-font-line-height--mobile;
            font-family: "BebasNeue-Regular";
            margin-bottom: 40px;

            @media #{$for-desktop} {
                font-size: $header-2-font-size--desktop;
            }
        }
    }

    .main-topic {
        max-width: 600px;
        margin: auto;
    }

    .section-header {

        @media #{$for-desktop} {
            margin-top: 30px;
        }
    }

    .step {
        text-transform: uppercase;
        font-family: 'BebasNeuePro-Bold';
        color: $color-green;
        font-size: 16px;
        margin-bottom: 5px;

        @media #{$for-desktop} {
            font-size: 20px;
        }
    }

    .subtitle {
        font-family: 'BebasNeuePro-Bold';
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;

        @media #{$for-desktop} {
            font-size: 28px;
        }
    }

    .text {
        font-size: 18px;
        line-height: 22px;

        @media #{$for-desktop} {
            margin: 0 auto;
            font-size: 20px;
            line-height: 24px;
        }
    }

    .separator {
        margin: 50px auto;
        width: 100%;
        height: 1px;
        background: lightgrey;
    }

    .content {
        margin: 80px 16px 500px;

        @media #{$for-desktop} {
            margin: 80px 0 500px;
        }

        .bottom-title {
            margin-bottom: 30px;
            font-size: 40px;
            font-family: "BebasNeue-Regular";
        }

        .bottom-content {
            a {
                color: $color-green;
            }

            p {
                text-align: justify;
            }

            h2, h3, h4 {
                font-family: 'BebasNeuePro-Bold';
            }

            h4 {
                margin-top: 30px;
            }
        }
    }
}
