@import "variables";
@import "breakpoints";
@import "mixins";
@import "fonts";

.main-container.simple.terms {
    main {
        min-height: 500px;
        text-align: justify;

        @media #{$for-desktop} {
            min-height: 800px;
        }
    }

    h3 {
        font-weight: 800;
        margin-top: 30px;
        margin-bottom: 10px;
    }
}
