@import "variables";

.seed-section {
    top: $main-seed-section-top--mobile;
    position: absolute;
    width: 100%;

    @media #{$for-desktop} {
        top: $main-seed-section-top--desktop;
    }

    .intro-head {
        @media #{$for-desktop} {
            padding: 0 !important;
        }
    }

    &__container {
        max-width: $max-text-column-width--mobile;
        padding: 0 $text-column-padding--mobile;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media #{$for-desktop} {
            top: 67px;
            position: relative;
            max-width: 580px;
        }
    }

    &__text {
        font-family: BebasNeuePro-Bold;
        margin-top: 30px;
        text-align: center;

        &--bottom {
            padding: 0 20px;
            max-width: 320px;

            @media #{$for-desktop} {
                margin-top: 30px;
                max-width: 466px;
            }
        }
    }

    &__image {
        svg {
            width: 170px;
            margin: 65px auto -20px;

            @media #{$for-desktop} {
                margin: 50px auto;
                width: 320px;
            }
        }
    }
}
