@import "variables";
@import "breakpoints";
@import "mixins";
@import "fonts";

.main-container.examples {
    background: url(../img/leaf.svg) no-repeat;
    background-size: 200px;
    background-position: left 50px;
    width: 375px;
    height: auto;
    overflow: hidden;
    position: relative;
    font-family: "BebasNeuePro-Book";

    font-size: $body-1-font-size--mobile;
    line-height: $body-1-font-line-height--mobile;
    z-index: 10;

    @media #{$for-desktop} {
        background: none;
        width: 100vw;
        font-size: $body-1-font-size--desktop;
        line-height: $body-1-font-line-height--desktop;
    }

    .art {
        display: none;

        @media #{$for-desktop} {
            display: block;
        }
        .art-left {
            position: absolute;
            top: 220px;
            left: 50%;
            margin-left: -1050px;
            z-index: -1;
            background-image: url("../img/examples/art_left.svg");
            background-size: auto;
            background-repeat: no-repeat;
            width: 700px;
            height: 2000px;
        }

        .art-right {
            position: absolute;
            top: 500px;
            left: 50%;
            margin-left: 400px;
            z-index: -1;
            background-image: url("../img/examples/art_right.svg");
            background-size: auto;
            background-repeat: no-repeat;
            width: 700px;
            height: 2000px;
        }

        .art-left-bottom {
            position: absolute;
            top: 2500px;
            left: 50%;
            margin-left: -700px;
            z-index: -1;
            background-image: url("../img/examples/art_left_bottom.svg");
            background-size: auto;
            background-repeat: no-repeat;
            width: 700px;
            height: 2000px;
        }

        .art-right-bottom {
            position: absolute;
            top: 1950px;
            left: 50%;
            margin-left: 400px;
            z-index: -1;
            background-image: url("../img/examples/art_right_bottom.svg");
            background-size: auto;
            background-repeat: no-repeat;
            width: 700px;
            height: 2000px;
        }

        .art-right-last {
            position: absolute;
            top: 3350px;
            left: 50%;
            margin-left: 400px;
            z-index: -1;
            background-image: url("../img/examples/art_right_last.svg");
            background-size: auto;
            background-repeat: no-repeat;
            width: 700px;
            height: 2000px;
        }
    }

    .main-topic {
        max-width: 600px;
        margin: 0 auto;
    }

    .icon {
        width: 100%;
        height: 50px;
        margin: 0 auto;
        text-align: center;

        @media #{$for-desktop} {
            flex: 1 1 auto;
            margin-bottom: 1em;
        }

        img {
            width: 100%;
            max-width: 70px;
            height: 100%;
            object-fit: contain;
            @media #{$for-desktop} {
                max-width: 110px;
                object-position: center;
            }
        }
    }

    .subtitle {
        font-family: 'BebasNeuePro-Bold';
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        @media #{$for-desktop} {
            font-size: 36px;
            line-height: 43px;
        }
    }

    .section-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        margin: 50px 0 10px;

        @media #{$for-desktop} {
            flex-direction: column;
        }
    }

    .text {
        position: relative;
        text-align: center;
        max-width: 330px;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0;
        margin: 0 auto 70px;
        overflow: visible;

        &:nth-of-type(2) {
            max-width: 380px;
        }

        &:nth-of-type(4) {
            max-width: 280px;
        }

        @media #{$for-desktop} {
            margin: 0 auto 110px;
            max-width: 700px;
            font-size: 20px;
            line-height: 24px;

            &:last-of-type {
                max-width: 440px;
            }

            &:nth-of-type(4) {
                max-width: 520px;
            }

            &:nth-of-type(8) {
                max-width: 490px;
            }

            &:nth-of-type(10) {
                &:before {
                    top: 0;
                    width: 23px !important;
                }

                &:after {
                    top: -28px;
                    width: 20px !important;
                }
            }

            &:nth-of-type(13) {
                max-width: 420px;
            }
        }

        &:before {
            display: block;
            content: "";
            width: 15px;
            height: calc(100% + 20px);
            position: absolute;
            top: -5px;
            bottom: 0;
            left: -30px;
            background-repeat: no-repeat;
            background-size: contain;

            @media #{$for-desktop} {
                height: 110%;
                top: -20px;
            }
        }

        &:after {
            display: block;
            content: "";
            width: 15px;
            height: calc(100% + 20px);
            position: absolute;
            top: -5px;
            bottom: 0;
            right: -30px;
            background-repeat: no-repeat;
            background-size: contain;

            @media #{$for-desktop} {
                height: 110%;
                top: -20px;
            }
        }

        &.small {
            &:before {
                top: -10px;
                left: -19px;
                background-image: url("../img/examples/brackets_left.svg");
                @media #{$for-desktop} {
                    left: -40px;
                }
            }

            &:after {
                top: -10px;
                right: -19px;
                background-image: url("../img/examples/brackets_right.svg");
                @media #{$for-desktop} {
                    right: -40px;
                }
            }
        }

        &.medium {
            &:before {
                top: -10px;
                background-image: url("../img/examples/brackets_left.svg");
                left: -15px;

                @media #{$for-desktop} {
                    left: -30px;
                }
            }
            &:after {
                top: -10px;
                background-image: url("../img/examples/brackets_right.svg");
                right: -15px;

                @media #{$for-desktop} {
                    right: -30px;
                }
            }
        }

        &.big {
            &:before {
                background-size: cover;
                background-image: url("../img/examples/bracketsbig_left.svg");
                left: -15px;
                width: 10px;

                @media #{$for-desktop} {
                    left: -30px;
                    background-size: contain;
                    width: 22px;
                }
            }

            &:after {
                background-size: cover;
                background-position: right;
                background-image: url("../img/examples/bracketsbig_right.svg");
                right: -15px;
                width: 8px;

                @media #{$for-desktop} {
                    right: -30px;
                    background-size: contain;
                    width: 19px;
                }
            }
        }
    }

    .content {
        margin-bottom: 200px;

        ul {
            list-style-type: none;
            margin-top: -10px;
        }

        li {
            margin-bottom: 0;
            text-indent: -5px;

        }

        li:before {
            content: "- ";
            text-indent: -5px;
        }
    }
}
