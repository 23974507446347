@import "variables";
/**
* Intro Section
*/

.mailing-list-section {
    height: $mobile-background-scale * 120px;
    position: absolute;
    width: 100%;
    top: $main-mailing-list-section-top--mobile;
    padding: 45px $text-column-padding--mobile;
    margin: 0 auto;

    @media #{$for-desktop} {
        top: $main-mailing-list-section-top--desktop;
        padding: 0 $text-column-padding--desktop;
        height: $desktop-background-scale * 130px;
    }

    & > div {
        max-width: $max-text-column-width--mobile;
        margin: 0 auto;
        padding: 0 $text-column-padding--mobile;

        @media #{$for-desktop} {
            max-width: $max-text-column-width--desktop;
        }
    }

    form.subscribe {
        width: 66%;

        @media #{$for-desktop} {
            width: 440px;
            margin: 0;
        }

        .form-control {
            width: 80px;
            height: 24px;
            padding: 0 10px;
            line-height: 24px;
            font-size: $form-input-font-size--mobile;

            @media #{$for-desktop} {
                width: 160px;
                height: 40px;
                font-size: $form-input-font-size--desktop;
            }
        }

        .input-group-append {
            margin-top: -1px;
            margin-left: 10px;

            @media #{$for-desktop} {
                margin-top: -8px;
                margin-left: 20px;
            }
        }
    }
}
