// The Sponsor page uses the simple template
// Note: see also the sponsor.scss file.
// This file adds styles for sponsor-specific elements

.main-container.sponsor {
  h2 {
    padding: 0 45px;
  }
  & ol {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 20px;
    margin-top: 40px;
    counter-reset: figure;
    justify-content: space-between;
    & li {
      flex: 0 1 100%;
      overflow: hidden;
      padding: 0 40px 50px 40px;
      list-style: none;
      counter-increment: figure;
      @media #{$for-desktop} {
        flex: 0 1 50%;
      }


      & > a {
        width: 100%;
        display: block;
        position: relative;
        color: black;
        &:before {

          content: counter(figure) ".";
          position: absolute;
          left: -2ch;
          font-weight: 700;
        }

        &:last-child {
          font-size: $figcaption-font-size--mobile;
          font-weight: 700;
          text-decoration: none;
          border-bottom: none;

          &:before {
            content: "";
            display: inline-block;
            background-image: url("/img/download_arrow.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            left: 0;
            margin-right: 10px;
            margin-left: 5px;
            vertical-align: text-bottom;
            position: relative;
          }
          @media #{$for-desktop} {
            font-size: $figcaption-font-size--desktop;
          }
        }
        & img {
          width: auto;
          max-width: 100%;
          max-height: 150px;
          object-fit: contain;
          object-position: left top;
          margin-bottom: 10px;
        }
      }
    }
  }
}
