@import "variables";

.approach-section {
    position: absolute;
    top: $main-methods-section-top--mobile;
    width: 100%;
    padding: 0 $text-column-padding--mobile;

    @media #{$for-desktop} {
        margin-left: 20px;
        top: $main-methods-section-top--desktop;
    }

    &__components {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media #{$for-desktop} {
            display: none;
        }


        &__heading {
            font-size: 15px;
            line-height: 18px;
            font-family: BebasNeuePro-Bold;
            font-weight: normal;
            margin-top: 5px;

            @media #{$for-desktop} {
                font-size: 34px;
                line-height: 41px;
                margin-top: 10px;
            }
        }

        &__items {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 10px;
            padding: 15px 5px 5px;
            background: #DBF3E9;
            border-radius: 35px;
            width: 300px;

            @media #{$for-desktop} {
                margin-top: 40px;
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 33.3%;

            &:first-of-type {
                max-width: 25%;
                margin-left: 10px;
                margin-right: 10px;
            }

            &:last-of-type {
                max-width: 30%;
                margin-right: 10px;
            }

            .title {
                font-family: "BebasNeuePro-Bold";
                line-height: 10px;
                font-size: 9px;
                font-weight: normal;
                text-transform: uppercase;
                margin: auto;
                height: 19px;

                @media #{$for-desktop} {
                    line-height: 29px;
                    font-size: 24px;
                }
            }

            .icon {
                margin-top: 10px;
                width: 45px;
                height: 25px;

                @media #{$for-desktop} {
                    margin-top: 20px;
                    width: 140px;
                    height: 85px;
                }
            }

            .description > p {
                font-family: "BebasNeuePro-Book";
                font-size: 8px;
                line-height: 10px;
                margin-top: 5px;
            }
        }
    }

    &__methods {
        margin: 40px auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #DBF3E9;
        border-radius: 40px;
        max-width: 235px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 1px;
            height: 90%;
            left: 50%;
            top: 5%;
            background-color: #00b374;
            transform: scaleX(40%);
        }

        &:after {
            position: absolute;
            content: "";
            width: 85%;
            height: 1px;
            left: 7.5%;
            top: 48%;
            background-color: #00b374;
            transform: scaleY(40%);
        }

        @media #{$for-desktop} {
            display: none;
        }


        &__heading {
            font-size: 15px;
            line-height: 18px;
            font-family: BebasNeuePro-Bold;
            font-weight: normal;
            margin-top: -28px;

            @media #{$for-desktop} {
                font-size: 34px;
                line-height: 41px;
                margin-top: 10px;
            }
        }

        &__items {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin: 12px 10px 0;
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 50%;

            .title {
                font-family: "BebasNeuePro-Bold";
                line-height: 12px;
                font-size: 9px;
                font-weight: normal;
                text-transform: uppercase;
                margin-bottom: 0;
            }

            .icon {
                margin-top: 5px;
                width: 45px;
                height: 22px;
            }

            .description > p {
                font-family: "BebasNeuePro-Book";
                font-size: 8px;
                line-height: 10px;
                margin-top: 5px;
            }
        }
    }
}
