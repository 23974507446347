.show-block {
    display: block;
}

.show-flex {
    display: flex;
}
.overflow-hidden {
    overflow: hidden;
}

.opaque {
    opacity: 1;
}
