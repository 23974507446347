@import "variables";

section.news-section {
    // second blob - problems we work on
    font-family: "BebasNeuePro-Book";
    position: absolute;
    top: $main-news-section-top--mobile;
    width: 100%;
    padding: 0 $text-column-padding--mobile;
    hyphens: auto;
    @media #{$for-desktop} {
        top: $main-news-section-top--desktop;
        left: 0;
    }

    .news-content {
        width: 100%;
        max-width: $max-text-column-width--mobile;
        margin: 0 auto;
        padding: $text-column-padding--mobile
            ($text-column-padding--mobile + 20px);

        @media #{$for-desktop} {
            max-width: $max-text-column-width--desktop;
            padding: 30px $text-column-padding--desktop;
        }
    }

    h4.news-header {
        font-size: $header-3-font-size--mobile;
        line-height: $header-3-font-line-height--mobile;
        font-family: "BebasNeue-Regular";
        margin-bottom: 20px;

        @media #{$for-desktop} {
            font-size: $header-2-font-size--desktop;
        }
    }

    .feeds {
        max-height: 275px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;

        @media #{$for-desktop} {
            max-height: $desktop-background-scale * 1100px;
            margin: 0;
            width: 100%;
        }

        .feed {
            margin-bottom: 40px;

            .feed-content {
                font-size: $news-feed-font-size--mobile;
                line-height: $news-feed-font-line-height--mobile;

                @media #{$for-desktop} {
                    font-size: $news-feed-font-size--desktop;
                    line-height: $news-feed-font-line-height--desktop;
                }
            }

            video {
                height: 250px;
            }

            img {
                max-width: 100%;
            }

            .feed-date {
                font-weight: 600;
                width: 30%;
                border-bottom-color: $color-green;
                border-bottom-style: solid;
                border-bottom-width: 1px;
                padding: 0 0 10px 0;
                font-size: $news-feed-font-size--mobile;
                line-height: $news-feed-font-line-height--mobile;

                @media #{$for-desktop} {
                    font-size: $news-feed-font-size--desktop;
                    line-height: $news-feed-font-line-height--desktop;
                    border-bottom-width: 2px;
                    padding: 0 0 20px 0;
                }
            }

            .invisible {
                visibility: visible !important;
            }

            a {
                color: $color-green;
                font-family: "BebasNeuePro-Bold";

                &:hover {
                    color: $color-dark-green;
                }
            }
        }
    }
}
