.joinus {
    height: auto;
    width: 100vw;
    font-size: $body-1-font-size--mobile;
    line-height: $body-1-font-line-height--mobile;
    z-index: 1;
    overflow: hidden;

    @media #{$for-desktop} {
        height: auto;
        font-size: $body-1-font-size--desktop;
        line-height: $body-1-font-line-height--desktop;
    }

    main {
        max-width: $mobile-width;
        margin: 0 auto;
        height: 100%;

        @media #{$for-desktop} {
            width: $desktop-breakpoint;
            max-width: 100%;
        }
    }

    footer {
        position: relative;
    }

    .joinus-header {
        text-align: center;
        font-family: "BebasNeue-Regular";
        font-size: $header-3-font-size--mobile;
        margin: 0;

        @media #{$for-desktop} {
            font-size: $header-2-font-size--desktop;
        }
    }

    .joinus-subheading {
        font-family: "BebasNeue-Regular";
        text-align: center;
        font-size: 20px;
        line-height: $header-3-font-line-height--mobile;
        @media #{$for-desktop} {
            font-size: 40px;
            line-height: $header-2-font-line-height--desktop;
            margin-bottom: 0.5em;
        }
    }

    .joinus-main-section {
        position: relative;
        width: 100%;
        padding: 0 $text-column-padding--mobile;
        font-size: $body-2-font-size--mobile;
        line-height: $body-2-font-line-height--mobile;

        @media #{$for-desktop} {
            font-size: $body-1-font-size--desktop;
            line-height: $body-1-font-line-height--desktop;
            padding: 0 $text-column-padding--desktop;
        }

        & > article {
            padding-top: 100px;

            @media #{$for-desktop} {
                padding-top: $joinus-desktop-background-scale * 300px;
            }
        }

        .main-topics {
            margin-top: $joinus-mobile-background-scale * 30px;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            font-size: $body-1-font-size--mobile;
            line-height: 22px;

            @media #{$for-desktop} {
                font-size: $body-1-font-size--desktop;
                line-height: 24px;
                padding: 0;
                margin-top: 100px;
            }

            .main-topic {
                text-align: center;
                width: 160px;

                a {
                    color: #000;
                    text-decoration: none;
                    border-bottom-color: $color-green;
                    border-bottom-style: solid;
                    border-bottom-width: 2px;

                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }

    .joinus-intro-section {
        z-index: $joinus-intro-z-index;
        overflow: hidden;
        position: relative;
        display: block;
        padding: 20px $mobile-background-scale * 20px 0;
        font-size: $body-1-font-size--mobile;
        line-height: $body-1-font-line-height--mobile;

        @media #{$for-desktop} {
            padding: 35px 50px 0;
            font-size: $body-1-font-size--desktop;
            line-height: $body-1-font-line-height--desktop;
        }
    }

    .joinus-intro {
        font-family: "BebasNeuePro-Book";
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media #{$for-desktop} {
            max-width: 750px;
            text-align: center;
            margin: 0 auto;
        }

        p {
            margin-bottom: 1em !important;
            letter-spacing: 0.5px;

            @media #{$for-desktop} {
                &:first-child {
                    max-width: 616px;
                }

                &:last-child {
                    max-width: 600px;
                }
            }

                a {
                color: #000;
                text-decoration: none;
                border-bottom-color: $color-green;
                border-bottom-style: solid;
                border-bottom-width: 2px;

                &:hover {
                    color: $color-green;
                }
            }
        }
    }

    .joinus-open-positions {
        position: relative;
        display: block;
        left: 0;
        width: 100%;
        padding-top: 80px;

        @media #{$for-desktop} {
            padding-top: 190px;
        }

        &__decor {
            position: absolute;
            width: 100%;
            height: 200px;
            top: -100px;
            left: 0;
            z-index: -99;
            background: url(../img/joinus/leaf-intro.svg) no-repeat center;

            @media #{$for-desktop} {
                display: none;
            }
        }

        .jobs {
            margin-top: -10px;

            @media #{$for-desktop} {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-row-gap: 60px;
                margin-top: 40px;
                margin-bottom: 50px;
            }

            .job {
                margin-top: 40px;
                @media #{$for-desktop} {
                    margin-top: 0;
                }

                &__title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: $header-4-font-size--mobile;
                    line-height: $header-4-font-line-height--mobile;
                    margin-bottom: 0.5em;

                    @media #{$for-desktop} {
                        flex-direction: column;
                        font-size: $header-3-font-size--desktop;
                        line-height: $header-3-font-line-height--desktop;
                        margin-bottom: 1em;
                    }
                }

                &__desc {
                    font-family: "BebasNeuePro-Book";
                    text-align: center;
                    padding: 0 $text-column-padding--mobile;
                    margin: 0 auto 1em;

                    p:last-child {
                        margin: 0;
                    }

                    @media #{$for-desktop} {
                        width: $desktop-background-scale * 500px;
                        margin-bottom: 1em;
                    }
                }

                &__exp {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    .title {
                        margin-top: 1em;
                    }

                    .content {
                        position: relative;
                        font-family: "BebasNeuePro-Book";
                        text-align: center;
                        padding: 0 15px 0 15px;
                        margin-bottom: 50px;

                        &:before {
                            display: block;
                            content: "";
                            width: 13px;
                            height: calc(90% + 65px);
                            position: absolute;
                            top: -20px;
                            bottom: 0;
                            left: 0;
                            background-repeat: no-repeat;
                            background-size: cover;

                            @media #{$for-desktop} {
                                height: calc(90% + 60px);
                            }
                        }

                        &:after {
                            display: block;
                            content: "";
                            width: 13px;
                            height: calc(90% + 65px);
                            position: absolute;
                            top: -20px;
                            bottom: 0;
                            right: 0;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center right;

                            @media #{$for-desktop} {
                                height: calc(90% + 60px);
                            }
                        }

                        &.medium {
                            padding: 0 25px;
                            &:before {
                                background-image: url("../img/joinus/bracket-left.svg");
                            }
                            &:after {
                                background-image: url("../img/joinus/bracket-right.svg");
                            }
                        }
                    }
                }

                &__title {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .icon {
                        width: 55px;
                        height: 55px;
                        object-fit: contain;
                        margin-bottom: 10px;
                        text-align: right;

                        @media #{$for-desktop} {
                            width: 85px;
                            height: 85px;
                            margin-bottom: 1em;
                        }
                    }
                }
            }
        }

        &__footer-graphic {
            display: none;

            @media #{$for-desktop} {
                width: $desktop-background-width;
                height: 100%;
                overflow: visible;
                margin: 0 auto;
                display: block;
                position: relative;
                top: -50px;
                z-index: -1;
                min-height: 500px * $joinus-desktop-background-scale;
            }

            .lottie_joinus-plant-3 {
                overflow: visible;
                position: absolute;
                // center the layer
                left: (
                $joinus-desktop-bg-native-width *
                    $joinus-desktop-background-scale - $desktop-background-width
            ) * -0.5;
                z-index: -999;
                // left: -50%;
                width: $joinus-desktop-bg-native-width *
            $joinus-desktop-background-scale !important;
                // height: $desktop-bg-native-height * $desktop-background-scale !important;
                height: auto !important;

                svg {
                    vertical-align: top !important;
                    object-fit: none;
                    object-position: center top;
                    overflow: visible;
                    width: 100%;
                }
            }
        }
    }

    .joinus-working {
        padding-top: 140px;
        position: relative;
        overflow: visible;

        @media #{$for-desktop} {
            padding-top: 30px;
        }

        &__decor {
            position: absolute;
            height: 200px;
            top: -30px;
            left: -50%;
            right: -50%;
            z-index: -99;
            background: url(../img/joinus/leaf-working.svg) no-repeat center;

            @media #{$for-desktop} {
                display: none;
            }
        }


        &__content {
            font-family: "BebasNeuePro-Book";
            margin: 40px auto 50px;
            padding: 0 15px;
            text-align: center;

            @media #{$for-desktop} {
                max-width: 675px;
                margin-bottom: 80px;
            }
        }

        &__video {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;

            @media #{$for-desktop} {
                margin-bottom: 200px;
            }

            iframe {
                width: 100%;
                height: auto;
                min-height: 210px;

                @media #{$for-desktop} {
                    width: 640px;
                    height: 360px;
                }
            }

            img {
                position: absolute;
                z-index: -1;
                left: 75px;
                bottom: -177px;
                display: none;

                @media #{$for-desktop} {
                    display: block;
                }
            }
        }
    }

    .lottie_joinus-plant-2 {
        top: 450px;
    }
}
