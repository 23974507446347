.single {
    footer {
        position: relative;
    }
}
footer {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding-bottom: 20px;
    z-index: $footer-z-index;

    .footer-section {
        width: 100%;
    }

    .footer-content {
        margin: 0 auto;
        width: 100%;
        padding: 20px 10px 40px 10px;

        @media #{$for-desktop} {
            max-width: $max-text-column-width--desktop;
        }
    }

    a {
        border-bottom: none !important;
    }

    p.copy {
        @media #{$for-desktop} {
            font-size: 16px;
        }
    }
}

.footer-bg {
    // background: url(../img/foot_back2.svg) repeat-x center bottom;
    background: linear-gradient(transparent 20%, $color-green);
    width: 100vw;
    margin: 0 auto;
    z-index: 0;
    position: relative;
    height: 400px;
    margin-top: -380px;

    @media #{$for-desktop} {
        height: 400px;
        margin-top: -400px;
    }
}

h5.footer-header {
    font-size: $header-3-font-size--mobile;
    font-family: "BebasNeue-Regular";
    text-align: center;
    margin-top: 80px;
    margin-bottom: 20px;

    @media #{$for-desktop} {
        visibility: hidden;
    }
}

.social {
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    font-size: $footer-font-size--mobile;
    max-width: $max-text-column-width--mobile;
    margin: 0 auto;

    @media #{$for-desktop} {
        max-width: 550px;
    }

    a {
        display: block;
        color: $color-dark-green;
        text-decoration: none;
        font-family: "BebasNeuePro-Middle";
        font-size: $body-2-font-size--mobile;
        line-height: $body-2-font-line-height--mobile;
        text-align: center;
        cursor: pointer;

        @media #{$for-desktop} {
            font-size: $news-feed-font-size--desktop;
            padding: 0 20px;
            height: 100%;
            display: block;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            @media #{$for-desktop} {
                max-width: 32px;
            }
        }

        span {
            white-space: nowrap;
        }

        div {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media #{$for-desktop} {
                margin-bottom: 10px;
                height: 50px;
            }
        }
    }

    a.linkedin {
        padding-left: 0;

        img {
            height: 23.36px;
            width: 23.38px;
        }
    }

    a.twitter {
        img {
            height: 18.11px;
            width: 20.57px;
            @media #{$for-desktop} {
                height: 24.11px;
                width: 26.57px;
            }
        }
    }

    a.mastodon {
        img {
            height: 29.79px;
            width: 27.72px;
        }
    }

    a.element {
        img {
            height: 29.78px;
            width: 29.78px;
        }
    }

    a.rss {
        img {
            width: 40px;
            height: 60px;
            object-position: 0 12px;
            @media #{$for-desktop} {
                max-width: 40px;
            }
        }
    }

    a.email {
        img {
            height: 21.18px;
            width: 30.52px;
        }
    }
}
