// Absolute position of sections on desktop view in order to align with the background graphics

// global
$mobile-background-width: 375px;
$mobile-background-scale: 1;

$mobile-width: 375px;
$tablet-breakpoint: 500px;
$desktop-breakpoint: 920px;
$desktop-background-width: 920px;

//Z-indexes
$header-z-index: 1010;
$footer-z-index: 1000;
$logo-big-z-index: 1020;
$main-container-z-index: 900;
$main-intro-z-index: 910;
$joinus-intro-z-index: 100;
$modal-container-z-index: 1100; //On top!

// landing page bg vars
$desktop-background-scale: 0.7;
$desktop-bg-native-width: 3800px;
$desktop-bg-native-height: 9000px;
$desktop-bg-native-y-offset: 230px;

// team page bg vars
$team-mobile-background-scale: 1;
$team-desktop-background-scale: 0.5;
$team-desktop-bg-native-width: 4400px;
$team-desktop-bg-native-y-offset: -575px;

// invoice page bg vars
$invoice-mobile-background-scale: 1;
$invoice-desktop-background-scale: 0.5;
$invoice-desktop-bg-native-width: 4400px;
$invoice-desktop-bg-native-y-offset: -575px;

// join us page bg vars
$joinus-mobile-background-scale: 1;
$joinus-desktop-background-scale: 0.5;
$joinus-desktop-bg-native-width: 4400px;
$joinus-desktop-bg-native-y-offset: -575px;

$header-height--desktop: 100px;

$max-text-column-width--mobile: 375px;
$max-text-column-width--desktop: 920px;

$text-column-padding--mobile: 20px;
$text-column-padding--desktop: 60px;

// deprecated vars
$vw: 375;
$vw2: 920;
$scalePoint: 2;
$scalePointFactor2: 1;
$scalePointFactor: 2;
$color-green: #0baf68;
$color-green-alpha50: rgba(11, 175, 104, 0.5);
$color-dark-green: #143011;

// menu bar
$menu-font-size--mobile: 16px;
$menu-font-size--desktop: 28px;

$header-1-font-size--mobile: 60px;
$header-1-line-height--mobile: 1.2em;

$header-1-font-size--desktop: 60px;
$header-1-line-height--desktop: 1.2em;

$header-2-font-size--mobile: 52px;
$header-2-font-line-height--mobile: 1.2em;

$header-2-font-size--desktop: 47px;
$header-2-font-line-height--desktop: 56px;

$header-3-font-size--mobile: 26px;
$header-3-font-line-height--mobile: 1.1em;

$header-3-font-size--desktop: 24px;
$header-3-font-line-height--desktop: 1.1em;

$header-4-font-size--mobile: 20px;
$header-4-font-line-height--mobile: 1.1em;

$header-4-font-size--desktop: 20px;
$header-4-font-line-height--desktop: 1.1em;

$header-5-font-size--mobile: 16px;
$header-5-font-line-height--mobile: 1.1em;

$body-1-font-size--mobile: 16px;
$body-1-font-line-height--mobile: 1.15em;

$body-1-font-size--desktop: 20px;
$body-1-font-line-height--desktop: 1.15em;

$body-2-font-size--mobile: 13px;
$body-2-font-line-height--mobile: 1.15em;

$body-2-font-size--desktop: 14px;
$body-2-font-line-height--desktop: 1.15em;

$intro-font-size--desktop: 28px;
$intro-font-line-height--desktop: 32px;

$news-feed-font-size--mobile: 16px;
$news-feed-font-line-height--mobile: 1.2em;

$news-feed-font-size--desktop: 20px;
$news-feed-font-line-height--desktop: 26px;

$team-person-info-font-size--mobile: 16px;
$team-person-info-font-line-height--mobile: 1.2em;
$team-person-info-font-size--desktop: 18px;
$team-person-info-font-line-height--desktop: 1.2em;

$button-font-size--mobile: 13px;
$button-font-line-height--mobile: 1em;
$button-font-size--desktop: 24px;
$button-font-line-height--desktop: 1em;

$form-input-font-size--mobile: 14px;
$form-input-font-size--desktop: 24px; // ie email input form

$footer-font-size--mobile: 12px;
$footer-font-size--desktop: 12px;

$footer-font-size--mobile: 12px;
$footer-font-size--desktop: 12px;

$figcaption-font-size--mobile: 14px;
$figcaption-font-size--desktop: 14px;

// Main page
// the positions are relative to the background image.
// A scale factor is applied so that if the background scale changes,
// the positions will move in correspondance.
$main-container-height--mobile: $mobile-background-scale * 3140px; // used to position the footer.
$main-container-height--desktop: $desktop-background-scale * 9850px; // used to position the footer.

$main-intro-section-top--mobile: $mobile-background-scale * 375px;
$main-intro-section-top--desktop: $desktop-background-scale * 200px;

$main-works-on-section-top--mobile: $mobile-background-scale * 630px;
$main-works-on-section-top--desktop: $desktop-background-scale * 1480px;

$main-methods-section-top--mobile: $mobile-background-scale * 1790px;
$main-methods-section-top--desktop: $desktop-background-scale * 5110px;

$main-seed-section-top--mobile: $mobile-background-scale * 1140px;
$main-seed-section-top--desktop: $desktop-background-scale * 2950px;

$main-mailing-list-section-top--mobile: $mobile-background-scale * 2360px;
$main-mailing-list-section-top--desktop: $desktop-background-scale * 7670px;

$main-news-section-top--mobile: $mobile-background-scale * 2462px;
$main-news-section-top--desktop: $desktop-background-scale * 7900px;

$main-table-header-font-family: "BebasNeuePro-Book";

$main-table-header-font-size--mobile: 16px;
$main-table-header-line-height--mobile: 1.2em;

$main-table-header-font-size--desktop: 28px;
$main-table-header-line-height--desktop: 1.2em;

$main-table-body-font-size--mobile: 14px;
$main-table-body-line-height--mobile: 1.1em;

$main-table-body-font-size--desktop: 24px;
$main-table-body-line-height--desktop: 1.3em;

// Team Page
$team-container-height--mobile: 2400px;
$team-container-height--desktop: 2800px;

$team-portrait-anim-scale--mobile: 0.37;
$team-portrait-anim-scale--desktop: 0.5;

// Join Us Page Heights
$joinus-container-height--mobile: 2100px;
$joinus-container-height--desktop: 2800px;

// Single Page Height
$single-container-height--mobile: auto;

// Invoice Page
