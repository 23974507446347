@import "variables";
@import "breakpoints";
@import "mixins";
@import "fonts";

.main-container.simple, .main-container.examples {
    background: url(../img/leaf.svg) no-repeat;
    background-size: 200px;
    background-position: left 50px;
    max-width: 375px;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    font-family: "BebasNeuePro-Book";

    font-size: $body-1-font-size--mobile;
    line-height: $body-1-font-line-height--mobile;
    z-index: 10;

    @media #{$for-desktop} {
        background: none;
        width: 100vw;
        max-width: 100%;
        font-size: $body-1-font-size--desktop;
        line-height: $body-1-font-line-height--desktop;
    }

    main {
        z-index: 999;
        margin: 0 auto;
        position: relative;
        min-height: 100%;
        padding: ($team-desktop-background-scale * 440px)
            $text-column-padding--mobile 0;

        @media #{$for-desktop} {
            width: $desktop-breakpoint;
            padding: ($team-desktop-background-scale * 340px) 80px 0;
            font-size: $body-1-font-size--desktop;
            line-height: $body-1-font-line-height--desktop;
            margin-bottom: 5em;
        }
    }

    a {
        font-weight: 600;
    }

    ol {
      & li > ul > li > a {
          color: #000;
          text-decoration: none;
          border-bottom-color: $color-green;
          border-bottom-style: solid;
          border-bottom-width: 2px;
      }
    }
    ul {
        padding-left: 1.35em;
        & li {
            margin-bottom: 1em;
            max-width: 90%;

            @media #{$for-desktop} {
                max-width: 100%;
            }
        }
        & li::marker {
            font-size: 1.25em;
        }
        & ul {
            padding-left: 1.35em;
            list-style: circle;
        }

        & p {
            display: inline-block;
        }
    }
    h1 {
        font-family: "BebasNeue-Regular";
        text-align: center;
        font-size: $header-3-font-size--mobile;
        line-height: $header-3-font-line-height--mobile;
        margin-top: 2em;
        &:first-child {
            // first header in main
            margin-top: 0;
            margin-bottom: 3em;
            @media #{$for-desktop} {
                margin-bottom: 1em;
            }
        }
        @media #{$for-desktop} {
            font-size: $header-2-font-size--desktop;
            line-height: $header-2-font-line-height--desktop;
            margin-bottom: 1em;
            margin-top: 2em;
        }
    }

    h2 {
        font-size: $body-1-font-size--mobile;
        line-height: $body-1-font-line-height--mobile;
        margin: 0.5em 0;
        font-weight: 600;
        @media #{$for-desktop} {
            font-size: $body-1-font-size--desktop;
            line-height: $body-1-font-line-height--desktop;
        }
    }

    footer {
        position: relative;
        display: block;
        & h5 {
            margin: 0;
            padding: 3em 0 1em 0;
        }
    }
}
