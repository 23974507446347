@import "variables";
@import "breakpoints";
@import "mixins";
@import "fonts";

.team-popup-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: $modal-container-z-index;
    display: none;
}

.team-popup {
    background: $color-green;
    width: 275px;
    min-height: 400px;
    padding: 40px 26px;
    margin: auto;
    position: relative;
    opacity: 0;
    transition: opacity 0.5s;

    @media #{$for-tablet} {
        width: 375px;
        min-height: 500px;
        padding: 60px 40px;
    }
    img {
        width: 100%;
    }
    h3,
    p {
        color: white;
        font-family: "BebasNeuePro-Regular";
    }
    h3 {
        font-size: (26rem / 16);
    }
    p {
        font-size: (20rem / 16);
    }
    .team-popup-upper {
        display: flex;
        svg {
            display: block;
        }
    }

    .team-popup-link {
        a {
            color: white;
            text-decoration: underline;
        }
    }

    .team-popup-bio {
        margin-top: 30px;
        line-height: 1.2;
    }

    .team-popup-corner-decoration {
        width: 52px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .hide {
        opacity: 0;
    }

    .empty {
        transform: none;
        clip-path: none;

        > image {
            transform: translate(5px, 10px) scale(0.9);
            clip-path: none;
            width: 140px;
            height: 140px;
        }
    }
}

.main-container.team {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    font-size: $body-1-font-size--mobile;
    line-height: $body-1-font-line-height--mobile;
    z-index: 10;
    padding-bottom: 300px;

    @media #{$for-desktop} {
        background: none;
        font-size: $body-1-font-size--desktop;
        line-height: $body-1-font-line-height--desktop;
    }

    main {
        z-index: 999;
        width: $mobile-width;
        margin: 0 auto;
        position: static;
        min-height: 100%;

        @media (max-width: 374px) {
            width: 100%;
        }

        @media #{$for-large-tablet} {
            max-width: 920px;
            width: 100%;
        }
    }

    .team-header-section {
        width: $mobile-background-width;
        margin: 0 auto;
        margin-top: 110px;

        @media #{$for-desktop} {
            position: static;
            margin-top: 170px;
        }

        h1.team-header {
            font-size: $header-3-font-size--mobile;
            line-height: $header-3-font-line-height--mobile;
            font-family: "BebasNeue-Regular";
            margin: 0;

            @media #{$for-large-tablet} {
                font-size: $header-2-font-size--desktop;
            }
        }
    }

    .team-members-section {
        .team-filter {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;

            @media #{$for-large-tablet} {
                justify-content: flex-end;
                margin-right: 30px;
            }

            @media #{$for-desktop} {
                margin-bottom: 30px;
                margin-right: 0px;
                margin-left: auto;
            }

            ul {
                display: flex;
                align-items: center;
                list-style: none;
                margin: 0;
                padding: 0;
                button {
                    display: flex;
                    align-items: center;
                    font-family: "BebasNeuePro-Regular";
                    appearance: none;
                    border: none;
                    background: none;
                    color: $color-green;
                    text-transform: uppercase;
                    font-size: (16rem / 16);
                    padding: 16px 8px;

                    @media #{$for-desktop} {
                        font-size: (20rem / 16);
                        padding: 20px 14px;
                    }

                    &.active-filter {
                        text-decoration: underline;

                        &::before {
                            background: url("/img/box_tick.svg");
                            background-size: contain;
                        }
                    }
                    &:not(.active-filter):hover {
                        &::before {
                            background: linear-gradient(
                                    $color-green-alpha50,
                                    $color-green-alpha50
                                ),
                                url("/img/box_tick.svg");
                            background-size: contain;
                        }
                    }
                    &:focus {
                        outline: 1px dotted $color-green-alpha50;
                    }
                    &::before {
                        content: "";
                        margin-right: 6px;
                        width: 18px;
                        height: 18px;
                        @media #{$for-desktop} {
                            width: 22px;
                            height: 22px;
                        }

                        border-radius: 50%;
                        background: $color-green;
                    }
                }
            }
        }

        .team-svg {
            width: 100%;
            height: auto;
            display: block;
        }

        .team-member-info {
            display: none;

            &.has-person {
                display: block;
                cursor: pointer;
                &:hover {
                    .team-member-name p {
                        text-decoration: underline;
                    }
                }
            }
        }

        .no-photo {
             path {
                stroke: transparent;
                fill: none;
            }

            > g {
                clip-path: none;
            }
        }

        .team-member-name {
            width: 70px;
            @media #{$for-large-tablet} {
                width: 150px;
            }
            p {
                text-transform: uppercase;
                color: $color-green;
            }
            &.left-side {
                @media #{$for-large-tablet} {
                    width: 120px;
                }

                p {
                    text-align: right;
                }
            }
        }
    }
}
