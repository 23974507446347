@import "variables";

section.works-on-section {
    top: $main-works-on-section-top--mobile;
    @media #{$for-desktop} {
        top: $main-works-on-section-top--desktop;
    }

    .works-on-article {
        @media #{$for-desktop} {
            width: 100%;
            position: absolute;
        }
    }

    .works-on-content {
        margin: 0 30px;
        min-width: 250px;

        @media #{$for-desktop} {
            width: $max-text-column-width--desktop;
            margin: 0 auto;
            padding: 0 40px;
            max-width: 520px;
        }

        header {
            margin-bottom: 30px;

            @media #{$for-desktop} {
                margin-bottom: 0;
            }
        }

        p {
            margin: 15px auto;
            max-width: 320px;

            @media #{$for-desktop} {
                line-height: 1.2em;
                margin-bottom: 1.1em;
                max-width: 100%;
            }
        }
    }
}
